.date-box {
	display: flex;
	flex-direction: column;
	width: 100px;
	height: 85px;
}

.month-box {
	flex: 1;
	padding: 4px;
	background-color: #fcc737;
	display: flex;
	justify-content: center;
	align-items: center;
}

.day-box {
	flex: 1;
	padding: 4px;
	background-color: #eaecef;
	text-align: center;
}