.wrapperLayout {
    height: 100vh;
}

.logo-title-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 7vh;
    background-color: white;
    padding-top: 3px;
    padding-bottom: 5px;
}

.logo-title-header-logo {
    height: 100%;
    margin-left: 10px;
}

.logo-title-header-title {
    margin-right: 10px;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 16px;
}

.adminLayout {
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
}

.layout {
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
}

.beforeLoginLayout {
    display: flex;
}

.errorLayout {
    display: block !important;
}

.layout-lng-buttons {
    position: absolute;
    top: 3%;
    right: 3%;
    transform: scale(0.8);
}

.layout-lng-buttons-admin {
    display: none !important;
}

.layout-lng-buttons .Mui-selected {
    background-color: #fcc737 !important;
}

.layout-logo {
    width: 50%;
}

.title {
    font-size: 32px;
    font-family: sans-serif;
    margin-top: 16px;
    margin-bottom: 16px;
}

.contentWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.adminContent {
    display: none;
    color: white;
}

.content {
    display: block;
    text-align: center;
    color: white;
    height: 85%;
    overflow-y: auto;
}

.beforeContent {
    height: 25%;
}

.buttonWrapper {
    margin-top: 50px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
}

.children {
    width: 100%;
    background-color: white;
}

@media only screen and (min-width: 768px) {
    .logo-title-header-title {
        font-size: 21px;
    }

    .logo-title-header-logo {
        margin-left: 20px;
    }

    .logo-title-header-title {
        margin-right: 30px;
    }
}

@media only screen and (min-width: 1100px) {
    .wrapperLayout {
        background-color: #474545;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    .logo-title-header {
        display: none;
    }

    .adminLayout {
        display: flex;
        width: 80vw;
        height: 80vh;
        justify-content: right
    }

    .layout {
        display: flex;
        width: 80vw;
        height: 80vh;
    }

    .layout-lng-buttons {
        top: 14%;
        right: 13%;
        transform: scale(1);
    }

    .layout-lng-buttons-admin {
        display: inline-flex !important;
        right: 53% !important;
    }

    .title {
        font-size: 44px;
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .content {
        overflow-y: auto;
        height: 80%;
    }

    .adminContentWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%
    }

    .adminContent {
        display: block;
        text-align: center;
        height: 80%;
    }

    .buttonWrapper {
        width: 250px;
    }

    .children {
        width: 50%;
        height: 80vh;
    }
}
