.change-password-new-password-eror-container {
    font-size: 0.8571428571428571rem;
    text-align: left;
    font-family: Roboto,Helvetica,Arial,sans-serif;
    font-weight: 400;
    line-height: 1.66;
    color: #f44336;

    margin-top: 3px;
    margin-left: 14px;
    margin-right: 14px;
}

.change-password-new-password-error-header {
    margin: 0;
}

.change-password-new-password-error-list {
    margin: 0;
}