.branch-selector {
	width: 300px;
}

.header-lng-switch {
	width: 100%;
	position: absolute;
	bottom: 9%;
	justify-content: center;
}

.header-lng-switch .Mui-selected {
	background-color: #fcc737 !important;
}

.header-lng-switch-workplace {
	width: 100%;
	position: absolute;
	bottom: 3%;
	justify-content: center;
}

.header-lng-switch-workplace .Mui-selected {
	background-color: #fcc737 !important;
}

.header-change-password-link {
	position: absolute !important;
	bottom: 5%;
	margin: 0 auto !important;
	width: 100%;
}

.header-logout-link {
	position: absolute !important;
	bottom: 2%;
	margin: 0 auto !important;
	width: 100%;
}

.MuiDrawer-paper {
	right: 10%;
	top: 10%;
	height: 80vh;
}

.MuiPaper-elevation1 {
	box-shadow: 0px -2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
}