.category-sum-above-threshold {
	color: red;
}

.date-validation .MuiOutlinedInput-notchedOutline {
	border-color: red !important;
}

.date-validation .MuiFormLabel-root {
	color: red !important;
}