.page {
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 93vh;
}

.fromWorkplacePage {
	height: 100vh !important;
}

@media only screen and (min-width: 1100px) {
	.page {
		height: 80vh;
	}
}