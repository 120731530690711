html, body {
    margin: 0px;
    overflow: hidden;
    background-color: rgb(255, 255, 255);
}

html {
    font-size: 14px;
}

.date-picker {
    flex-grow: 1;
    max-width: 130px;
}

.slide-enter {
    opacity: 0;
    transform: translateX(-100vw);
    position: absolute;
}

.slide-enter.slide-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 250ms linear 0s;
}

.slide-exit {
    opacity: 1;
    transform: translateX(0);
}

    .slide-exit.slide-exit-active {
        opacity: 0;
        transform: translateX(100vw);
        transition: all 250ms linear;
    }

.donation-categories {
    padding: 20px;
}

.donation-categories-header {
    padding-left: 12px;
    padding-top: 12px;
    color: rgba(0, 0, 0, 0.87);
}

.donation-categories-desc, .donation-categories-desc.MuiTypography-body1 {
    font-size: 1rem;
    padding-left: 12px;
    padding-top: 12px;
    color: rgba(0, 0, 0, 0.87);
}

#submission-comments {
    margin-bottom: 12px;
}

.feedback-received-english {
    padding-bottom: 10px;
}

.feedback-received-french {
    padding-top: 10px;
}

.feedback-regards {
    margin-top: 1.5em;
}

.facebook-blank-page {
    margin-top: 20px;
    margin-left: 24px;
    margin-right: 24px;
}

img {
    max-width: 100%;
}
